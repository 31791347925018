import { Button } from "@chakra-ui/button";
import { ListItem, OrderedList, VStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useMutation, useQuery } from "react-query";
import { WORKER_URL } from "./config";

/**
 * Modal component that handles submitting new posts.
 */
function SubmitPostModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (newPost?: any) => void;
}) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");

  const { isSuccess, isLoading, mutate, reset } = useMutation(async () => {
    await fetch(WORKER_URL + "/posts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title,
        content,
        username,
      }),
    });
  });

  // Close window when post is added successfully
  useEffect(() => {
    if (isOpen && isSuccess) {
      onClose({ title, content, username });
      setTitle("");
      setContent("");
      setUsername("");
      reset();
    }
  }, [isOpen, isSuccess, onClose, content, title, username, reset]);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(undefined)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Submit a Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              mutate();
              e.preventDefault();
            }}
          >
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Content</FormLabel>
                <Textarea
                  type="text"
                  required
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </FormControl>
              <Button type="submit" isLoading={isLoading}>
                Submit
              </Button>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * Loads and displays posts from the CloudFlare KV worker.
 */
export function PostsList() {
  const { data } = useQuery<any[]>("posts", () =>
    fetch(WORKER_URL + "/posts").then((res) => res.json())
  );

  // Whether submit post modal is open
  const [isOpen, setOpen] = useState(false);

  // Client-side cached list of new posts, to avoid issues from Cloudflare
  // KV's eventual consistency
  const [newPosts, setNewPosts] = useState<any[]>([]);

  useEffect(() => {
    // Whenever data changes, clear client cache
    setNewPosts([]);
  }, [data]);

  const posts = [...data!, ...newPosts];

  return (
    <>
      <OrderedList>
        {posts.map((post, idx) => (
          <ListItem key={idx} marginBottom="1em">
            <details>
              <summary>
                <strong>{post.title}</strong> by {post.username}
              </summary>

              <ReactMarkdown children={post.content}></ReactMarkdown>
            </details>
          </ListItem>
        ))}
      </OrderedList>

      <p>
        <Button onClick={() => setOpen(!isOpen)}>New Post</Button>
      </p>

      <SubmitPostModal
        isOpen={isOpen}
        onClose={(post) => {
          if (post) {
            setNewPosts([...newPosts, post]);
          }
          setOpen(false);
        }}
      />
    </>
  );
}
