import {
  Box,
  ChakraProvider,
  Container,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { PostsList } from "./PostsList";

// Configure query client to use React-Suspense
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

export function AppWrapper({ children }: { children: React.ReactNode }) {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>{children}</ChakraProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

function App() {
  // If you complain about the appearance of this website, you need to explain
  // how a Popular Social Network with 5.5 M views daily looks very similar.
  // Clearly, it's a winning formula.
  return (
    <AppWrapper>
      <Box padding="4px">
        <Container maxW="1200px">
          <Box background="#274690" color="#F5F3F5" padding="4px">
            <Heading size="md" display="inline">
              <span
                style={{
                  border: "1px solid white",
                  display: "inline-block",
                  padding: "0 6px",
                }}
              >
                &Omega;
              </span>{" "}
              Omegaverse News
            </Heading>{" "}
            The low-code edition of the metaverse.
          </Box>
          <Box padding="1em" background="#c7d2eb">
            <Suspense fallback={<Spinner />}>
              <PostsList />
            </Suspense>

            <p style={{ marginTop: "1em" }}>&copy; 2021 Kevin Liu</p>
          </Box>
        </Container>
      </Box>
    </AppWrapper>
  );
}

export default App;
